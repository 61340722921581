<template>
  <BasePageSection
    :bg-image="bgImg"
    :bg-color="bgColor"
    :content-bg-color="contentBgColor"
    v-bind="{ ...$attrs, ...$props }"
  >
    <VLayout
      row
      wrap
      fill-height
      align-center
      :class="['section--layout', { 'no-img': !hasImg }]"
    >
      <VFlex
        v-bind="textSectionAttrs"
        :[textLayoutOrder]="true"
        :class="[
          { 'pt-5': !['xs', 'sm'].includes($mq) },
          { 'py-2': ['xs', 'sm'].includes($mq) },
        ]"
      >
        <BasePageContent
          :content-key="`ns_${sectionId}_title`"
          tag-class="header-1 section--inner-title pb-3 mb-3 d-inline-block"
          tag="h3"
        />
        <div :class="['text-content', { 'pb-2': ['xs', 'sm'].includes($mq) }]">
          <slot />
        </div>
      </VFlex>
      <VFlex
        v-if="hasImg"
        :class="['text-xs-center', { 'px-3': ['md'].includes($mq) }]"
        sm5
        md4
        offset-md1
        xs12
        :[imgLayoutOrder]="true"
      >
        <img :src="img" :alt="imgCaption || `${getSiteTitle} Image`" />
        <BasePageContent
          :content-key="`ns_${sectionId}_img_caption`"
          tag-class="subheading text-xs-center"
          tag="div"
        />
      </VFlex>
    </VLayout>
  </BasePageSection>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PageSectionNarrative',
  props: {
    sectionId: {
      type: [String, Number],
      default: null,
    },
    fullBleedBg: {
      type: Boolean,
      default: false,
    },
    fullBleedContent: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getSiteTitle']),
    ...mapGetters('pages', ['getThePage', 'getPageContent']),
    bgImg() {
      return this.getContent('bg_image')
    }, // bgImg
    bgColor() {
      return this.getContent('bg_color')
    }, // bgColor
    contentBgColor() {
      return this.getContent('content_bg_color')
    }, // contentBgColor
    img() {
      return this.getContent('img')
    }, // img
    imgCaption() {
      return this.getContent('img_caption')
    }, // imgCaption
    imgPos() {
      return this.getContent('img_pos') || 'right'
    }, // imgPos
    hasImg() {
      return !!this.img
    }, // hasImg
    textSectionAttrs() {
      if (this.hasImg) {
        return {
          md5: true,
          'offset-md1': true,
          sm7: true,
          xs12: true,
          ...(['md'].includes(this.$mq) && { 'pl-4': true }),
        }
      } else {
        return {
          lg5: true,
          md6: true,
          'offset-md2': true,
          sm8: true,
          'offset-sm1': true,
          xs12: true,
        }
      }
    }, // textSectionAttrs
    textLayoutOrder() {
      return this.imgPos === 'right' ? 'order-xs1' : 'order-xs2'
    }, // textLayoutOrder
    imgLayoutOrder() {
      return this.imgPos === 'right' ? 'order-xs2' : 'order-xs1'
    }, // imgLayoutOrder
    allPropsAndAttrs() {
      return { ...this.$props, ...this.$attrs }
    }, // allPropsAndAttrs
  },
  methods: {
    getContent(key) {
      return this.getPageContent(`ns_${this.sectionId}_${key}`)
    }, // getContent
  },
}
</script>

<style lang="scss">
@import '@design';

.section--layout {
  padding-top: $size-grid-padding * 1;
  padding-bottom: $size-grid-padding * 1;
  &.no-img {
    padding-top: $size-grid-padding * 3;
    padding-bottom: $size-grid-padding * 3;
  }
  img {
    width: 100%;
  }
}

.section--inner-title {
  border-bottom: 2px solid $color-db-purple;
}

.text-content {
  font-size: 20px;
  letter-spacing: 0.012em;
}

.subheading {
  // TODO (ESS): dont hardcode text
  font-family: 'Asap', sans-serif;
  font-style: italic;
}
</style>
